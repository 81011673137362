import './App.css';
import MartianLogo from './images/martian-logo-white-bg.png'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import React from 'react';
import Hubspot from './Hubspot';

const RedirectWithDelay = ({url}) => {
    window.location.href = url;
    return null;
};

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/30" element={<RedirectWithDelay url="https://calendly.com/d/zy2-ccn-g8x/30-minute-chat-with-founders"/>}/>

                <Route path="/60" element={<RedirectWithDelay url="https://calendly.com/d/z99-54z-dp9/1-hour-chat-with-founders"/>}/>

                <Route path="/" element={
                    <div className="App">
                        <div className="white">
                            <header className="App-header">
                                <div className="logo-image"
                                     style={{backgroundImage: `url(${MartianLogo})`, backgroundSize: 'cover'}}/>
                                <div className="logo-text">Martian</div>
                                <div style={{clear: 'both'}}/>
                            </header>
                        </div>
                        <div className="gray">
                            <a href="https://calendly.com/d/zy2-ccn-g8x/30-minute-chat-with-founders">
                                <div className="notebox" style={{marginBottom: 100}}>
                                    <h1>Book a 30-minute meeting</h1>
                                </div>
                            </a>
                            <a href="https://calendly.com/d/z99-54z-dp9/1-hour-chat-with-founders">
                                <div className="notebox" style={{marginBottom: 50}}>
                                    <h1>Book a 60-minute meeting</h1>
                                </div>
                            </a>
                        </div>
                    </div>
                }/>

                <Route path="/get-started" element={
                    <div className="App">
                        <div className="white-subpage">
                            <header className="App-header">
                                <div className="logo-image"
                                     style={{backgroundImage: `url(${MartianLogo})`, backgroundSize: 'cover'}}/>
                                <div className="logo-text">Martian</div>
                                <div style={{clear: 'both'}}/>
                            </header>
                        </div>
                        <div className="gray">
                            <Hubspot link="https://meetings.hubspot.com/etan-ginsberg/martian-onboarding-with-founders?embed=true" />
                        </div>
                    </div>
                } />

                <Route path="/enterprise" element={
                    <div className="App">
                        <div className="white-subpage">
                            <header className="App-header">
                                <div className="logo-image"
                                     style={{backgroundImage: `url(${MartianLogo})`, backgroundSize: 'cover'}}/>
                                <div className="logo-text">Martian</div>
                                <div style={{clear: 'both'}}/>
                            </header>
                        </div>

                        <div className="gray">
                            <Hubspot link="https://meetings.hubspot.com/etan-ginsberg/enterprise-sales-website-inbound?embed=true" />
                        </div>
                    </div>
                } />

                <Route path="/chat-with-founders" element={
                    <div className="App">
                        <div className="white-subpage">
                            <header className="App-header">
                                <div className="logo-image"
                                     style={{backgroundImage: `url(${MartianLogo})`, backgroundSize: 'cover'}}/>
                                <div className="logo-text">Martian</div>
                                <div style={{clear: 'both'}}/>
                            </header>
                        </div>

                        <div className="gray">
                            <Hubspot link="https://meetings.hubspot.com/etan-ginsberg/exploratory-call?embed=true" />
                        </div>
                    </div>
                } />
            </Routes>
        </Router>
    );
}

export default App;